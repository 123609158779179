import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'

const IndexPage = () => {
   return (
      <Layout>
         <div className="relative">
            <div className="bg-gradient-to-b from-cyan-700/50 via-cyan-100/0 to-yellow-100/0 z-50 absolute inset-x-0 top-0 h-96" />
            <StaticImage
               src="../images/hero.jpg"
               alt="A dinosaur"
               placeholder="blurred"
               quality={100}
               layout="fullWidth"
               aspectRatio={2.5}
               className="lg:h-[750px] h-full z-30"
            />
         </div>
         <section className="mx-auto max-w-7xl px-4 md:px-6 lg:px-8 mb-20">
            <div className="bg-white md:p-20 p-6 shadow-xl rounded-xl -mt-20 z-50 relative">
               <span className="md:text-4xl text-2xl text-cyan-700 font-thin">
                  Βιογραφικό
               </span>
               <div className="flow-root mt-8">
                  <div className="my-4 leading-7">
                     Ο κ. Σαρδαρίδης Φιλάρετος είναι ομογενής από την πρώην
                     ΕΣΣΔ. Γεννήθηκε το 1973.Σπούδασε ιατρική στα Πανεπιστήμια
                     στις πόλεις Τιούμεν και Σταυροπόλ της Ρωσίας . Μετά την
                     ολοκλήρωση τον σπουδών ακολούθησε εκπαίδευση στην
                     ειδικότητα της ουρολογίας στην Πανεπιστημιακή κλινική της
                     Σταυροπόλ ( καθ. Ντερεβιανκο Ιγκορ Μιχαηλοβιτς ) για 2,5
                     χρόνια.
                  </div>
               </div>
               <div className="flow-root">
                  <div className="my-4 leading-7">
                     Μετά τον επαναπατρισμό έδωσε εξετάσεις ΔΙΚΑΤΣΑ και πέρασε
                     με βαθμό « Λίαν Καλός » το 2006 . Υπηρέτησε ως αγροτικός
                     ιατρός στο Π.Ι. Ποντοηράκλειας Κ.Υ. Πολυκάστρου Κιλκίς .
                     Ύστερα ακολούθησε την εκπαίδευση στη Γενική Χειρουργική στο
                     Γ.Ν. Κατερίνης για 1 χρόνο και μετά στην Ουρολογία στο
                     Γ.Ν.Κοζάνης « Μαμάτσειο » για 1,5 χρόνο.
                  </div>
               </div>
               <div className="flow-root">
                  <div className="my-4 leading-7">
                     Συνέχισε την ειδικότητα για άλλα 3 χρόνια στην Α
                     Πανεπιστημιακή κλινική του Γ.Ν.Θ. Γ.Γεννηματάς ( καθ.
                     Ραδόπουλος Δ. , καθ.Κατσίκας Β. ). Ο ιατρός εκπαιδεύτηκε
                     στην Γενική Ουρολογία , Ουρογυναικολογία , Ανδρολογία ,
                     Ογκοουρολογία . Εκτός απ’ αυτά συμμετείχε στην εκπαίδευση
                     φοιτητών Ιατρικής Σχολής ΑΠΘ , Νοσηλευτών και στο
                     επιστημονικό έργο της κλινικής . Έχει δημοσιεύσεις στα
                     Ελληνικά συνέδρια , περιοδικά και του εξωτερικού.
                  </div>
               </div>
               <div className="flow-root">
                  <div className="my-4 leading-7">
                     Μετά την απόκτηση τίτλου ειδικότητας Χειρουργού - Ουρολόγου
                     το 2014 και μέχρι σήμερα ο ιατρός διατηρεί ιατρείο στην
                     περιοχή Πολίχνη –Θεσσαλονίκης . Χειρουργεί στις ιδιωτικές
                     κλινικές : Αγιος Λουκάς , Βιοκλινική ( πρώην Γαλινός ).
                     Εχει μεγάλη συμμετοχή σε συμπόσια , συνέδρια εκπαιδευτικά
                     σεμινάρια οσο στην Ελλάδα τόσο ναι στο εξωτερικό ( Ευρώπη ,
                     Ασία )
                  </div>
               </div>
               <div className="flow-root">
                  <div className="my-4">
                     Τα βασικά κριτήρια του ιατρού είναι ειλικρίνεια , σεβασμό
                     στον συνάνθρωπο του συμπόνια και επιστημονική προσέγγιση
                  </div>
               </div>
               <div className="flow-root">
                  <div className="my-4">
                     «Δεν χειρουργούμε για να χειρουργούμε , χειρουργούμε για να
                     προσφέρουμε το καλύτερο δυνατό αποτέλεσμα για τον ασθενή
                     μας»
                  </div>
               </div>
            </div>
         </section>
      </Layout>
   )
}

export default IndexPage

export function Head() {
   return (
      <title>Βιογραφικό | Σαρδαρίδης Φιλάρετος Ανδρολόγος Ουρολόγος</title>
   )
}